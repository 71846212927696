import React, { useState } from 'react';

// import './App.css'
import { Link, useLocation } from 'react-router-dom';
const Soical = () => {
    
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const image = queryParams.get('image'); // Get the image parameter
 // State to track if any checkbox is selected
 const [isSelected, setIsSelected] = useState(false);

 // Handle checkbox click
 const handleCheckboxClick = () => {
   setIsSelected(true); // Set to true when any checkbox is clicked
 };
  return (
   <>
    
    <nav className="border-bottom" id="navbar">
        <div className="container">
            <div className="d-flex flex-column justify-content-between   p-2">
                <div  style={{
          width: '100%', // The div will take up the full width of its parent container
       
        }} className="d-flex align-items-center justify-content-between  ">
                    <a href="/">
                        <img className="headerimg w-50 h-50" src={require('./images/logo.jpg')} alt="Logo" />
                    </a>
                </div>
            </div>
        </div>
    </nav>
  
    <div className="container">
     
    <div className="row" style={{marginTop:"200px"}}>
        <div className="col-12 col-md-6 col-lg-6 ">
            <div className="left-section">
                <div className="high-demand mx-auto" style={{ width: 'fit-content' }}>
                  🔥 High Demand
                  
                </div>
                <span className="text-danger">590 people are interested in this product</span>
                <div className="product-title " />
                  Nike Kobe 5 Protro<br/>White Thunder
                </div>
                <div>
                    <img src={require(`./images/${image}.jpg`)} alt="" />
                </div>
              </div>
       
        <div className="col-12 col-md-5 col-lg-6 py-5 "  >
            <div className="container text-center mt-5" style={{
          backgroundColor: '#e7e7e7', 
          padding:"20px" // Set the background color to #e7e7e7
        }}>
                <h3>Which platform do you plan on promoting our sneakers on</h3>
                <div className="d-flex justify-content-center flex-wrap gap-2 mt-3">
                   {/* Platform Checkboxes */}
      <label className="platform-checkbox" onClick={handleCheckboxClick}>
        <input type="checkbox" value="Instagram" />
        <i className="fab fa-instagram" style={{ color: '#E4405F' }}></i> Instagram
      </label>
      <label className="platform-checkbox" onClick={handleCheckboxClick}>
        <input type="checkbox" value="Reddit" />
        <i className="fab fa-reddit" style={{ color: '#FF4500' }}></i> Reddit
      </label>
      <label className="platform-checkbox" onClick={handleCheckboxClick}>
        <input type="checkbox" value="TikTok" />
        <i className="fab fa-tiktok" style={{ color: '#000' }}></i> TikTok
      </label>
      <label className="platform-checkbox" onClick={() => { 
        handleCheckboxClick(); 
        alert('d'); 
      }}>
        <input type="checkbox" value="Snapchat" />
        <i className="fab fa-snapchat" style={{ color: '#FFFC00' }}></i> Snapchat
      </label>

      {/* Conditionally render the Next button */}
      
              </div>
              <center>
              {isSelected && (
        <div className="mt-4">
          <Link
            className="whole_button nextButton mt-4"
            id="nextButton"
            style={{
              display: 'block',
              backgroundColor: 'green',
              color: 'white',
              padding: '10px 20px',
              textAlign: 'center',
              cursor: 'pointer',
              textDecoration: 'none',
            }}
            to={`/payment?image=${image}`}
          >
            Next
          </Link>
        </div>
      )}
              
              </center>
              </div>
              
           </div>
        </div>
   
        </div>
      
     
   
   </>
  )
}

export default Soical;