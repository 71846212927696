import React, { useState } from 'react';

// import './App.css'
import { Link, useLocation } from 'react-router-dom';
const Size = () => {
    const [selectedSize, setSelectedSize] = useState(null);
    const [buttonshow, setButtonShow] = useState(null);
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const image = queryParams.get('image'); // Get the image parameter
   // Function to handle the click event
   const handleSizeClick = (size) => {
    setSelectedSize(size); // Set the selected size when clicked
  };
  // Array of sizes for rendering
  const sizes = [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5];
  return (
   <>
     <img style={{
          height: '50%',
          width: '40%',
        
        }} src={require('./images/logo.jpg')} alt="" />
<div className="container">
 
<div className="row">
    <div className="col-12 col-md-6 col-lg-8">
        <div className="left-section">
            <div className="high-demand mx-auto" style={{
          width: 'fit-content', 
        
        }}>
              🔥 High Demand
              
            </div>
            <span className="text-danger">590 people are interested in this product</span>
            <div className="product-title ">
              Nike Kobe 5 Protro<br/>White Thunder
            </div>
            <div>
                <img  src={require(`./images/${image}.jpg`)} alt="" />
            </div>
          </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
        <div className="right-section">
            <h3>Select your shoe size</h3>
            <select className="gender-select">
              <option>Select Gender</option>
              <option>US Women</option>
              <option>US Men</option>
             
            </select>
        
            <div className="size-options">
             
            {sizes.map((size) => (
        <div
          key={size}
          className="size-option"
          onClick={() => handleSizeClick(size)}
          style={{
            backgroundColor: selectedSize === size ? 'green' : '', // Change background color when selected
            color: selectedSize === size ? 'white' : '',          // Change text color when selected
            padding: '10px',
            margin: '5px',
            cursor: 'pointer',  // Optional: change cursor to pointer on hover
          }}
        >
          {size}
        </div>
      ))}
            </div>
            <center>
            {selectedSize && (
        <div className="mt-4">
          <Link
            className="whole_button nextButton mt-4"
            id="nextButton"
            style={{
              display: 'block', // Make sure the button is displayed when selectedSize is set
              backgroundColor: 'green',
              color: 'white',
              padding: '10px 20px',
              textAlign: 'center',
              cursor: 'pointer',
              textDecoration: 'none',
            }}
            to={`/promotion?image=${image}`}
          >
            Next
          </Link>
        </div>
      )}
            </center>
            
          </div>
    </div>
</div>

  
 
</div>
   </>
  )
}

export default Size;