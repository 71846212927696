import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Home';
import GetStarted from './GetStarted';
import SelectItem from './SelectItem.js';
import ItemShow from './ItemShow.js';
import Size from './Size.js';
import Otp from './Otp.js';
import Register from './Register.js';
import Soical from './Soical.js';
import Payment from './Payment.js';
import Username from './Username.js';
import Final from './Final.js';
// import SecondPage from './SecondPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/getStart" element={<GetStarted />} />
        <Route path="/shop" element={<SelectItem />} />
        <Route path="/product" element={<ItemShow />} />
        <Route path="/size" element={<Size />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/login" element={<Register />} />
        <Route path="/promotion" element={<Soical />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/username" element={<Username />} />
        <Route path="/confirm" element={<Final />} />
      </Routes>
    </Router>
  );
}

export default App;
