import React from 'react';
// import './App.css'
import {  Link } from 'react-router-dom';
const GetStarted = () => {
  return (
   <>
       <div className="container">
        <div style={{ maxWidth: '760px', margin: '0 auto' }}>
            <div className="d-flex gap-5 flex-column justify-content-center align-items-center">
                <img src={require('./images/logo.jpg')} alt="" />
                <h3 className="fs_30 fw_700 j_family text-center">Start earning when you promote our sneakers on your social
                    media page and get payouts via PayPal, Cashapp or Zelle.</h3>
                <Link type='button' to={'/shop'} className="whole_button">Select a Pair of Shoes</Link>
                <h2>How It Works</h2>
                <div className="row align-items-center">
                    <div className="col-2"><img className="img-fluid" src={require('./images/step1.png')} /></div>
                    <div className="col-10">
                        <h2 className="fw_700 fs_18">STEP 1: </h2>
                        <p className="fs_18 fw_600">Select a pair of sneakers, we have many Addias, Jordans, Nike and more
                            to choose from</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-2"><img className="img-fluid" src={require('./images/setp2.png')} /></div>
                    <div className="col-10">
                        <h2 className="fw_700 fs_18">STEP 2: Enter the required info </h2>
                        <p className="fs_18 fw_600">Enter your size, shipping info, payout method and confirm that you own the account you'll be promoting on</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-2"><img className="img-fluid" src={require('./images/1.png')} /></div>
                    <div className="col-10">
                        <h2 className="fw_700 fs_18">STEP 3: </h2>
                        <p className="fs_18 fw_600">After you enter the required info, we'll send you a pdf contact via email where you can review and sign it.</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-2"><img className="img-fluid" src={require('./images/step4.png')} /></div>
                    <div className="col-10">
                        <h2 className="fw_700 fs_18">STEP 4:</h2>
                        <p className="fs_18 fw_600">Get paid when a follower is inspired to buy. The more you share, the more you earn!</p>
                    </div>
                </div>
                <Link type='button' to={'/shop'} className="whole_button" href="select-items.html">Get Started</Link>

            </div>
        </div>
    </div>
    <div className="container-fluid px-0 mt-5">
        <div className="d-flex">
            <img style={{ width: '100%', height: 'auto' }} classNames="img-fluid" src={require('./images/icons/sitting-person.jpg')} />
        </div>
    </div>
    <div className="container">
        <div className="mt-5" style={{ maxWidth: '760px', margin: '0 auto' }}>
            <div className="d-flex flex-column gap-2">
                <h3 className="fs_60 fw_700 j_family text-center text-md-start">
                    Perks
                </h3>
                <p className="pt-3 fs_20 fw_600 j_family text-center text-md-start">
                    We review all ambassador applications based on audience fit, overall alignment to the KOF brand,
                    and quality of content. Once accepted to the program, you’ll have access to the following:
                </p>
                <div className="d-flex flex-wrap flex-md-nowrap align-items-center">
                    <ul>
                        <li className="fw_700 fs_18">Earn up to 3% on all KOF sales</li>
                        <li className="fw_700 fs_18 mt-2">Monthly Cash Bonuses based on performance targets</li>
                        <li className="fw_700 fs_18 mt-2">Exclusive access to KOF events, promotions and gifting suite to our top performers</li>
                    </ul>
                    <ul>
                        <li className="fw_700 fs_18  mt-2">Site credit for your content</li>
                        <li className="fw_700 fs_18 mt-2">Ambassador newsletter with exclusive product launches and perks to help you launch</li>
                        <li className="fw_700 fs_18 mt-2">Dedicated Ambassador management team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
   </>
  )
}

export default GetStarted;